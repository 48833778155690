<template>
  <div class="m-goals-list">
    <b-notification
      v-if="deleteError"
      type="is-danger"
      has-icon
      :aria-close-label="$t('generic.closeMessageButton')"
      role="alert"
    >
      {{ $t('goals.goalDeleteError') }}
    </b-notification>
    <b-table :data="goals" :paginated="true" :per-page="20" :loading="loading" default-sort="name">
      <b-table-column field="id" label="ID" sortable v-slot="props">
        <router-link :to="{ name: 'goal-edit', params: { goalId: props.row.id } }">{{ props.row.id }}</router-link>
      </b-table-column>

      <b-table-column field="aspirantUser" :label="$t('goals.aspirant')" sortable v-slot="props">
        {{ props.row.aspirantUser }}
      </b-table-column>

      <b-table-column field="primaryGoal" :label="$t('goals.primaryGoal')" v-slot="props">
        {{ props.row.primaryGoal }}
      </b-table-column>

      <b-table-column field="secondaryGoal" :label="$t('goals.secondaryGoal')" v-slot="props">
        {{ props.row.secondaryGoal }}
      </b-table-column>

      <b-table-column field="deadline" :label="$t('goals.deadline')" sortable centered v-slot="props">
        <span class="tag">
          {{ props.row.deadline }}
        </span>
      </b-table-column>

      <b-table-column field="date" :label="$t('generic.modifiedDate')" sortable centered v-slot="props">
        <span class="tag">
          {{ props.row.modified }}
        </span>
      </b-table-column>

      <b-table-column field="options" :label="$t('generic.options')" centered v-slot="props">
        <b-button type="is-danger" icon-right="trash" @click="confirmDelete(props.row.id)" />
      </b-table-column>
      <template slot="empty">
        <no-data-to-list />
      </template>
    </b-table>
  </div>
</template>
<script>
import * as actions from '../../../store/actions';
import * as namespaces from '../../../store/namespaces';
import logger from '../../../utils/logger';
import NoDataToList from '../_fragments/NoDataToList';

export default {
  name: 'GoalsList',
  components: { NoDataToList },
  data() {
    return {
      isMenuActive: true,
      loading: false,
      loadingError: false,
      deleteError: false
    };
  },
  computed: {
    goals() {
      let data = [];
      if (this.$store.state.goals.all) {
        this.$store.state.goals.all.forEach(item => {
          let company = item.aspirantUser.company ? ' (' + item.aspirantUser.company + ')' : '';
          data.push({
            id: item._id,
            aspirantUser: item.aspirantUser.nameLast + ' ' + item.aspirantUser.nameFirst + company,
            primaryGoal: item.primaryGoal ? this.$t('feedback.thanks[' + (item.primaryGoal - 1) + '].title') : '-',
            secondaryGoal: item.secondaryGoal
              ? this.$t('feedback.thanks[' + (item.secondaryGoal - 1) + '].title')
              : '-',
            endOfWeekReminder: item.endOfWeekReminder,
            deadline: new Date(item.deadline).toLocaleDateString(),
            modified: new Date(item.updatedAt).toLocaleDateString()
          });
        });
      }
      return data;
    }
  },
  mounted() {
    this.loading = true;
    this.loadingError = false;
    this.$store
      .dispatch(namespaces.GOALS + actions.FETCH_GOALS)
      .catch(error => {
        this.loadingError = true;
        logger.error(error);
      })
      .finally(() => {
        this.loading = false;
      });
  },
  methods: {
    confirmDelete(goalId) {
      this.$buefy.dialog.confirm({
        message: this.$t('goals.confirmDelete'),
        cancelText: this.$t('generic.cancelButton'),
        confirmText: this.$t('generic.yesButton'),
        focusOn: 'cancel',
        onConfirm: () => {
          this.$store
            .dispatch(namespaces.GOALS + actions.DELETE_GOAL, goalId)
            .then(() => {
              this.deleteError = false;
            })
            .catch(error => {
              this.deleteError = true;
              logger.error(error);
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    }
  }
};
</script>
